import request from '@/utils/request'


// 根据用户id,获取会话记录 GET system/chatgpt/list
export function chatList(){
  return request({
    url: '/system/chatgpt/list',
    method: 'get'
  })
}


// 发送问题 POST system/chatgpt/sendQuestion
export function SendQuestion(data){
  return request({
    url: '/system/chatgpt/sendQuestion',
    method: 'POST',
    data: data
  })
}

export function SendQuestionByCorpusId(data){
  return request({
    url: '/system/chatgpt/sendQuestionByCorpusId',
    method: 'POST',
    data: data
  })
}

export function SendQuestionQwen(data){
  return request({
    url: '/system/chatgpt/sendQuestionQwen',
    method: 'POST',
    data: data
  })
}

export function generateForm(data){
  return request({
    url: '/system/chatgpt/generateFormJson',
    method: 'POST',
    data: data
  })
}

export function generateFormQwen(data){
  return request({
    url: '/system/chatgpt/generateFormQwen',
    method: 'POST',
    timeout: 0,
    data: data
  })
}

// 根据问题id,获取回答记录 GET system/chatgpt/{askid}
export function chatAnswer(askId){
  return request({
    url: '/system/chatgpt/'+askId,
    method: 'get'
  })

}
