var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "my-form-left left-menu" }, [
        _c("ul", { staticClass: "tab-title" }, [
          _c(
            "li",
            {
              class: { active: _vm.cur === 1 },
              on: {
                click: function ($event) {
                  return _vm.onTabClick(1)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "tab-title-icon",
                attrs: { "icon-class": "myCreateNew" },
              }),
              _c("span", [_vm._v("我创建的")]),
            ],
            1
          ),
          _c(
            "li",
            {
              class: { active: _vm.cur === 2 },
              on: {
                click: function ($event) {
                  return _vm.onTabClick(2)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "tab-title-icon",
                attrs: { "icon-class": "mySubmitNew" },
              }),
              _c("span", [_vm._v("我提交的")]),
            ],
            1
          ),
          _c(
            "li",
            {
              class: { active: _vm.cur === 3 },
              on: {
                click: function ($event) {
                  return _vm.onTabClick(3)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "tab-title-icon",
                attrs: { "icon-class": "mySubmitNew" },
              }),
              _c("span", [_vm._v("我审核的")]),
            ],
            1
          ),
          _c(
            "li",
            {
              class: { active: _vm.cur === 4 },
              on: {
                click: function ($event) {
                  return _vm.onTabClick(4)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "tab-title-icon",
                attrs: { "icon-class": "myManageNew" },
              }),
              _c("span", [_vm._v("我管理的")]),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticStyle: { width: "16px" } }),
      _c("div", { staticClass: "my-form-right" }, [
        _c(
          "div",
          { staticClass: "right-form-list" },
          [
            _c(
              "div",
              { staticClass: "form-list-top" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "fl",
                    attrs: { inline: true, size: "small" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-bottom": "0" } },
                      [
                        _c("el-input", {
                          staticClass: "search-input",
                          staticStyle: { width: "190px", "font-size": "14px" },
                          attrs: {
                            placeholder: "请输入应用或表单名称",
                            "prefix-icon": "el-icon-search",
                            clearable: "",
                          },
                          on: { clear: _vm.onClearName },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.onHandleQuery($event)
                            },
                          },
                          model: {
                            value: _vm.queryParams.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "name", $$v)
                            },
                            expression: "queryParams.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-bottom": "0" } },
                      [
                        _c("formTagEditor", {
                          staticStyle: { width: "140px" },
                          attrs: {
                            multiple: false,
                            selectonly: true,
                            tagList: _vm.tagList,
                          },
                          model: {
                            value: _vm.queryParams.tagName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "tagName", $$v)
                            },
                            expression: "queryParams.tagName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { "margin-bottom": "0" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-search",
                              plain: "",
                            },
                            on: { click: _vm.onHandleQuery },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-refresh" },
                            on: { click: _vm.onResetQuery },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "fr" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "list-switch",
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.listStyle,
                          callback: function ($$v) {
                            _vm.listStyle = $$v
                          },
                          expression: "listStyle",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "card" } }, [
                          _c("i", { staticClass: "el-icon-bank-card" }),
                          _vm._v(" 卡片"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "list" } }, [
                          _c("i", { staticClass: "el-icon-tickets" }),
                          _vm._v(" 列表"),
                        ]),
                      ],
                      1
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-dropdown",
                      {
                        staticStyle: { "margin-left": "8px", float: "right" },
                        attrs: {
                          "split-button": "",
                          type: "primary",
                          size: "small",
                        },
                        on: {
                          click: _vm.onNewFormClick,
                          command: _vm.templateCreateFormCommand,
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "btn-create-icon",
                          attrs: { "icon-class": "formCreateForm" },
                        }),
                        _vm._v("创建表单 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.createForm($event)
                                  },
                                },
                              },
                              [_vm._v("从模版创建")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: this.user.adminAgencyId,
                                    expression: "this.user.adminAgencyId",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.createFormByAI($event)
                                  },
                                },
                              },
                              [_vm._v("AI创建")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.user.type != "1"
                      ? _c(
                          "el-dropdown",
                          {
                            staticStyle: {
                              "margin-left": "8px",
                              float: "right",
                            },
                            attrs: {
                              "split-button": "",
                              type: "warning",
                              size: "small",
                            },
                            on: {
                              click: _vm.onNewProjectClick,
                              command: _vm.templateCreateFormCommand,
                            },
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "btn-create-icon",
                              attrs: { "icon-class": "formCreateProject" },
                            }),
                            _vm._v("创建应用 "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.createProject($event)
                                      },
                                    },
                                  },
                                  [_vm._v("从模版创建")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("fillList", {
              attrs: {
                list: _vm.projectList,
                listStyle: _vm.listStyle,
                cur: _vm.cur,
                isShowListError: _vm.isShowListError,
                tagList: _vm.tagList,
              },
              on: {
                "get-refresh-list": _vm.refreshProjectList,
                editProject: function ($event) {
                  return _vm.onEditProject($event)
                },
              },
            }),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.total > this.queryParams.pageSize ||
                    (_vm.queryParams.pageSize != 16 &&
                      _vm.queryParams.pageSize != 5),
                  expression:
                    "total > this.queryParams.pageSize||(queryParams.pageSize!=16 && queryParams.pageSize!=5)",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.queryParams.pageNum,
                limit: _vm.queryParams.pageSize,
                pageSizes: _vm.pageSizes,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.queryParams, "pageNum", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.queryParams, "pageSize", $event)
                },
                "update:pageSizes": function ($event) {
                  _vm.pageSizes = $event
                },
                "update:page-sizes": function ($event) {
                  _vm.pageSizes = $event
                },
                pagination: _vm.onPageProjectList,
              },
            }),
          ],
          1
        ),
      ]),
      _c("project-detail-edit", {
        ref: "projectDetailEditor",
        attrs: { user: _vm.user, tagList: _vm.tagList },
        on: {
          "update:tagList": function ($event) {
            _vm.tagList = $event
          },
          "update:tag-list": function ($event) {
            _vm.tagList = $event
          },
          updateProjectList: _vm.getProjectList,
        },
      }),
      _c("bindUserMobile"),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.templateImportVisible,
            "destroy-on-close": "",
            width: "40%",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.templateImportVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  ref: "templateImportFileUpload",
                  attrs: {
                    drag: "",
                    action: "#",
                    multiple: false,
                    limit: 1,
                    accept: ".txt",
                    "http-request": _vm.handleFileUpload,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _c("p", [
                      _vm._v("将文件拖到此处，或"),
                      _c("em", [_vm._v("点击上传")]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [_vm._v("只能上传txt文件，且不超过2M")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.generateFormLoading,
              expression: "generateFormLoading",
            },
          ],
          attrs: {
            title: _vm.title,
            visible: _vm.createFormAIVisible,
            "custom-class": "el-dialog-hide-header",
            "element-loading-text": "正在生成表单...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
            width: "60%",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createFormAIVisible = $event
            },
            close: _vm.closeAiDialog,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    rules: _vm.aiRules,
                    model: _vm.formData,
                    size: "medium",
                  },
                },
                [
                  _c("el-form-item", { attrs: { prop: "askMessage" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("el-input", {
                          style: { width: "85%", marginRight: "10px" },
                          attrs: {
                            placeholder: "请输入表单生成要求",
                            clearable: "",
                          },
                          model: {
                            value: _vm.formData.askMessage,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "askMessage", $$v)
                            },
                            expression: "formData.askMessage",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.designForm },
                          },
                          [_vm._v("帮我设计表单")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-input", {
                    ref: "aiTextarea",
                    style: { width: "100%" },
                    attrs: {
                      type: "textarea",
                      placeholder: "",
                      autosize: { minRows: 15, maxRows: 20 },
                    },
                    on: {
                      scroll: function (e) {
                        return _vm.console.log(e)
                      },
                    },
                    model: {
                      value: _vm.formData.answer,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "answer", $$v)
                      },
                      expression: "formData.answer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: {
                    color: "red",
                    display: "inline-block",
                    "margin-top": "0",
                    "margin-left": "5px",
                    "font-size": "14px",
                  },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " 注意：可以根据需要手动编辑,仅支持字段标题以(单行输入)|(多行输入)|(单选)|(多选)结尾！ "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "button-container",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.generateFormQwen },
                    },
                    [_vm._v("生成表单")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }