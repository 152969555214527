<template v-cloak>
  <div class="app-container" style="position: relative;">
    <!-- <div class="my-form-left" :class="[hasScrollbar ? 'isFixed' : '']"> -->
    <div class="my-form-left left-menu">
      <ul class="tab-title">
        <!-- <li @click="onTabClick(0)" :class="{active:cur===0}">
                    <svg-icon icon-class="myManageNew" class="tab-title-icon" />
                    <span>机构首页</span>
                </li> -->
        <li @click="onTabClick(1)" :class="{active:cur===1}">
          <svg-icon icon-class="myCreateNew" class="tab-title-icon" />
          <span>我创建的</span>
        </li>
        <li @click="onTabClick(2)" :class="{active:cur===2}">
          <svg-icon icon-class="mySubmitNew" class="tab-title-icon" />
          <span>我提交的</span>
        </li>
        <li @click="onTabClick(3)" :class="{active:cur===3}">
          <svg-icon icon-class="mySubmitNew" class="tab-title-icon" />
          <span>我审核的</span>
        </li>
        <li @click="onTabClick(4)" :class="{active:cur===4}">
          <svg-icon icon-class="myManageNew" class="tab-title-icon" />
          <span>我管理的</span>
        </li>
        <!-- <li @click="onTabClick(5)" :class="{active:cur===5}">
                    <svg-icon icon-class="myRelatedList" class="tab-title-icon" />
                    <span>我参与的</span>
                </li> -->
      </ul>
    </div>
    <div style="width:16px"></div>
    <div class="my-form-right">
      <!-- <div class="my-form-right" :style="{marginLeft: hasScrollbar ? '280px' : ''}"> -->
      <div class="right-form-list">
        <div class="form-list-top">
          <el-form :inline="true" size="small" class="fl" @submit.native.prevent>
            <el-form-item style="margin-bottom: 0;">
              <el-input v-model="queryParams.name" placeholder="请输入应用或表单名称" prefix-icon="el-icon-search" class="search-input" clearable style="width: 190px;font-size: 14px;" @keyup.enter.native="onHandleQuery" @clear="onClearName" />
            </el-form-item>
            <el-form-item style="margin-bottom: 0;">
              <formTagEditor v-model="queryParams.tagName" :multiple="false" :selectonly="true" :tagList="tagList" style="width: 140px;"></formTagEditor>
            </el-form-item>
            <el-form-item style="margin-bottom: 0;">
              <el-button type="primary" icon="el-icon-search" @click="onHandleQuery" plain>搜索</el-button>
              <el-button icon="el-icon-refresh" @click="onResetQuery">重置</el-button>
            </el-form-item>
          </el-form>
          <span class="fr">
            <el-radio-group v-model="listStyle" size="mini" class="list-switch">
              <el-radio-button label="card"><i class="el-icon-bank-card"></i> 卡片</el-radio-button>
              <el-radio-button label="list"><i class="el-icon-tickets"></i> 列表</el-radio-button>
            </el-radio-group>
            <el-divider direction="vertical"></el-divider>
            <!--机构管理员、子机构管理员-->
            <el-dropdown split-button type="primary" size="small" @click="onNewFormClick" @command="templateCreateFormCommand" style="margin-left: 8px;float: right;">
              <svg-icon icon-class="formCreateForm" class="btn-create-icon" />创建表单
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="createForm">从模版创建</el-dropdown-item>
                <el-dropdown-item v-show="this.user.adminAgencyId" @click.native="createFormByAI">AI创建</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown split-button v-if="user.type != '1'" type="warning" size="small" @click="onNewProjectClick" @command="templateCreateFormCommand" style="margin-left: 8px;float: right;">
              <svg-icon icon-class="formCreateProject" class="btn-create-icon" />创建应用
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="createProject">从模版创建</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
        <fillList :list="projectList" :listStyle="listStyle" @get-refresh-list="refreshProjectList" @editProject="onEditProject($event)" :cur="cur" :isShowListError="isShowListError" :tagList="tagList" />
        <!-- </div> -->
        <pagination v-show="total > this.queryParams.pageSize||(queryParams.pageSize!=16 && queryParams.pageSize!=5)" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :pageSizes.sync="pageSizes" @pagination="onPageProjectList" />
      </div>
    </div>

    <project-detail-edit ref="projectDetailEditor" :user="user" :tagList.sync="tagList" @updateProjectList="getProjectList"></project-detail-edit>
   
    <!-- 强制绑定该用户的手机号 -->
    <bindUserMobile></bindUserMobile>
    <el-dialog :title="title" :visible.sync="templateImportVisible" destroy-on-close width="40%" append-to-body :close-on-click-modal="false">
      <div>
        <el-upload drag ref="templateImportFileUpload" action="#" :multiple="false" :limit="1" accept=".txt" :http-request="handleFileUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <p>将文件拖到此处，或<em>点击上传</em></p>
            <div class="el-upload__tip" slot="tip">只能上传txt文件，且不超过2M</div>
          </div>
        </el-upload>
      </div>
    </el-dialog>
    <!--  AI创建表单  -->
    <el-dialog :title="title" :visible.sync="createFormAIVisible" 
              custom-class="el-dialog-hide-header"
              v-loading="generateFormLoading" element-loading-text="正在生成表单..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" @close="closeAiDialog" width="60%" append-to-body
              :close-on-click-modal="false">
      <div>
        <el-form ref="elForm" :rules="aiRules" :model="formData" size="medium">
          <el-form-item prop="askMessage">
            <div style="display:flex">
              <el-input v-model="formData.askMessage" placeholder="请输入表单生成要求" clearable :style="{width: '85%',marginRight:'10px'}">
              </el-input>
              <el-button type="primary" @click="designForm">帮我设计表单</el-button>
            </div>
          </el-form-item>
          <el-input v-model="formData.answer" ref="aiTextarea" type="textarea" placeholder="" :autosize="{minRows: 15, maxRows: 20}" :style="{width: '100%'}" @scroll="e=>console.log(e)"></el-input>
        </el-form>
        <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px; font-size:14px;" slot="tip">
          注意：可以根据需要手动编辑,仅支持字段标题以(单行输入)|(多行输入)|(单选)|(多选)结尾！
        </div>
        <div slot="footer" class="button-container">
          <el-button type="primary" @click="generateFormQwen">生成表单</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import wangEditor from 'wangeditor'
import fillList from "@/views/system/form/list/fillList";

import { getToken } from "@/utils/auth";
import { getSessionStorageObj, setSessionStorageObj } from "@/utils/db";
import { putFile, setOssConfig } from "@/utils/ossUtils";
import { getDateDiff, parseTime } from "@/utils/whale";
import {
  projectList,
  project,
  projectDetail,
  editProject,
  getTagListByUserId,
  createFormByTemplate,
  createProjectByTemplate,
} from "@/api/system/form";
import {
  SendQuestionByCorpusId,
  chatAnswer,
  generateForm,
  SendQuestionQwen,
  generateFormQwen,
} from "@/api/system/ai";
import formTagEditor from "@/views/system/form/components/formTag/editor.vue";
import { aliUploader } from "@/utils/oss";
import bindUserMobile from "./components/forceBindMobile/bindUserMobile";
// import fileUploadMixin from '../fileUploadMixin'
import DesignView from "./components/designView.vue";

const websocketUrl = process.env.VUE_APP_WEBSOCKET_BASE_URL;

import projectDetailEdit from '@/views/system/form/components/projectDetailEdit/index.vue'

export default {
  name: "Form",
  components: {
    fillList,
    formTagEditor,
    bindUserMobile,
    DesignView,
    projectDetailEdit
  },
  data () {
    return {
      formData: {
        askMessage: "",
        answer: "",
        askId: "",
      },
      random: "",
      generateFormLoading: false,
      intervalHandlerQwen: "",
      listStyle: "card",
      cur: 1,
      queryType: "create",
      projectList: [],
      total: 0,
      user: {},
      queryParams: {
        pageNum: 1,
        pageSize: 16,
        name: null,
        startDate: null,
        endDate: null,
        tagName: "",
      },
      pageSizes: [5, 10, 16, 20],
      formType: "",
      hasScrollbar: false, // 当滚动条高度 this.scrollHeight 大于60时是否定位
      scrollHeight: 60,
      title: undefined,
      templateImportType: "form",
      templateImportVisible: false,
      createFormAIVisible: false,
      
      tagList:[],
      fileList: [],
      projectId: null, // 应用ID，为了修改应用信息
      editor: {},
      isShowListError: false,
      sessionStorageUser: getSessionStorageObj("users"),
      aiRules: {
        askMessage: [
          { required: true, message: '请输入表单生成要求', trigger: "blur" },
          { max: 1500, message: '最多输入1500字', trigger: ["change", "blur"] }
        ],
      },
    };
  },
  created () {
    if (this.agencyId == null) {
      this.agencyId =
        getSessionStorageObj("agencyId") ||
        this.sessionStorageUser.agencyIds.split(",")[0];
      this.queryParams.agencyId = this.agencyId;
    }

    this.$store.dispatch("GetUserInfo").then((res) => {
      this.user = res.data;
    });

    if (getSessionStorageObj("listCur")) {
      this.cur = getSessionStorageObj("listCur");
    }
    this.getTagListByUserId();
    this.getProjectList(this.cur);

    this.Authorization = getToken();
    this.random = this.generateRandomNumber();
  },
  mounted () {
    window.addEventListener("scroll", this.initHeight);
    
  },
  destroyed () {
    window.removeEventListener("scroll", this.initHeight, false);
  },
  watch: {
    listStyle: {
      deep: true,
      handler () {
        if (this.listStyle === "list") {
          this.queryParams.pageSize = 5;
          this.getProjectList();
        } else {
          this.queryParams.pageSize = 16;
          this.getProjectList();
        }
      },
    },
  },
  methods: {
    // uploadResume (index, fileList) {
    //   this.formFilesUpload.resume(index);
    // },
    // 实现吸顶效果，判断滚动条距离顶部的距离
    initHeight () {
      const self = this;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      self.hasScrollbar = scrollTop > self.scrollHeight;
    },
    onTabClick (index) {
      this.queryParams = {
        pageNum: 1,
        pageSize: this.listStyle === "list" ? 5 : 16,
        name: null,
        startDate: null,
        endDate: null,
      };
      this.formType = "";
      this.projectList = [];
      this.total = 0;
      this.cur = index;
      this.getProjectList(this.cur);
      setSessionStorageObj("listCur", this.cur);
    },
    // 点击分页 请求列表
    onPageProjectList (data) {
      this.queryParams = {
        pageNum: data.page,
        pageSize: data.limit,
        name: this.queryParams.name,
        startDate: this.queryParams.startDate,
        endDate: this.queryParams.endDate,
      };
      this.getProjectList(this.cur);
    },
    getProjectList () {
      let cur = this.cur;
      const _this = this;
      if (cur === 0) {
        _this.queryType = "agencyHome";
      } else if (cur === 1) {
        _this.queryType = "create";
      } else if (cur === 2) {
        _this.queryType = "fill";
      } else if (cur === 3) {
        _this.queryType = "review";
      } else if (cur === 4) {
        _this.queryType = "data";
      }
      // else if (cur === 5) {
      //   _this.queryType = 'related'
      // }
      projectList(_this.queryType, _this.queryParams).then((response) => {
        if (response.code === 200) {
          let records = response.rows;
          let fillStartTime, fillEndTime;
          let formStateTxt = ""; // 表单状态（未开始、进行中、已结束）
          for (let i = 0; i < records.length; i++) {
            records[i].showBtn = false;
            // 应用
            if (records[i].type == "project" && records[i].formTotalNum > 0) {
              if (
                !this.isBlank(records[i].formListVos) &&
                records[i].formListVos.length > 0
              ) {
                records[i].isSubShow = false;
                for (let j = 0; j < records[i].formListVos.length; j++) {
                  records[i].formListVos[j].showBtn = false;

                  const curTime = response.now || parseTime(new Date());
                  if (records[i].formListVos[j].status == "1") {
                    formStateTxt = "未发布";
                  } else {
                    fillStartTime =
                      records[i].formListVos[j].fillStartTime ||
                      "1970-01-01 00:00:00";
                    fillEndTime =
                      records[i].formListVos[j].fillEndTime ||
                      "2099-12-31 23:59:59";
                    formStateTxt = this.getFormTime(
                      curTime,
                      fillStartTime,
                      fillEndTime
                    );
                  }
                  records[i].formListVos[j].formStateTxt = formStateTxt;
                }
              }
            }
            // 默认表单
            if (records[i].type == "form") {
              const curTime = response.now || parseTime(new Date());
              if (records[i].status == "1") {
                formStateTxt = "未发布";
              } else {
                fillStartTime =
                  records[i].fillStartTime || "1970-01-01 00:00:00";
                fillEndTime = records[i].fillEndTime || "2099-12-31 23:59:59";
                formStateTxt = this.getFormTime(
                  curTime,
                  fillStartTime,
                  fillEndTime
                );
              }
              records[i].formStateTxt = formStateTxt;
            }
          }
          _this.projectList = records;
          _this.total = response.total;
          if (_this.projectList.length > 0) {
            _this.isShowListError = false;
          } else {
            _this.isShowListError = true;
          }
        } else {
          _this.msgError(response.msg);
        }
      });
    },
    getFormTime (curTime, fillStartTime, fillEndTime) {
      let formStateTxt = "";
      if (getDateDiff(fillStartTime, curTime)) {
        formStateTxt = "未开始";
      } else if (
        getDateDiff(curTime, fillStartTime) &&
        getDateDiff(fillEndTime, curTime)
      ) {
        formStateTxt = "进行中";
      } else {
        formStateTxt = "已结束";
      }
      return formStateTxt;
    },
    // 子组件传值，要求父组件刷新列表
    refreshProjectList () {
      this.getProjectList(this.cur);
    },
    onResetQuery () {
      this.queryParams = {
        pageNum: 1,
        pageSize: this.listStyle === "list" ? 5 : 16,
        name: null,
        startDate: null,
        endDate: null,
      };
      this.getProjectList(this.cur);
    },
    onHandleQuery () {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = this.listStyle === "list" ? 5 : 16;
      this.getProjectList(this.cur);
    },
    onClearName () {
      this.queryParams.name = null;
      this.getProjectList(this.cur);
    },
    // 创建应用
    onNewProjectClick () {
      this.$refs.projectDetailEditor.newProject();

    },
    async getTagListByUserId () {
      let res = await getTagListByUserId();
      this.tagList = res.data.tagList;
    },
    // 创建应用 确定点击
   
    // 帮我设计表单
    designForm () {
      const param = {
        askMessage: this.formData.askMessage,
        userIdRandom: this.random,
      };
      // 清空
        this.formData.answer = "";
        SendQuestionQwen(param).then((response) => {
          if (response.code == 200) {
            if (this.formData.answer == '') {
              this.formData.answer = response.data;
            }
            // 开启轮巡
            //this.getChatAnswerInterval()
          }
        });
      },
      // 轮询根据问题，ID每5秒调用一次getChartAnswer
      getChatAnswerInterval () {
        // 间隔时间
        let seconds = 5000;
        let askId = this.formData.askId;
        const intervalHandler = setInterval(() => {
          chatAnswer(askId).then(res => {
            if (res.code == 200) {
              // console.log(res.data)
              if (res.data.answer) {
                clearInterval(intervalHandler);
                this.formData.answer = res.data.answer;
              }
            }
          })
        }, seconds);
      },
      // 通义千问生成表单
      generateFormQwen() {
        const param = {
          askMessage: this.formData.answer,
          agencyId: this.user.adminAgencyId,
          userIdRandom : this.random,
        };
        this.generateFormLoading = true;
        generateForm(param).then((response) => {
          if (response.code == 200) {
            // 创建成功
            console.log("AI创建表单成功")
            clearInterval(this.intervalHandlerQwen);
            this.generateFormLoading = false;
            this.createFormAIVisible = false;
            this.getProjectList(this.cur);
          }
        }).catch(ex=>{
          this.generateFormLoading = false;
          this.createFormAIVisible = false;
        });
      },
    
    // 修改应用
    onEditProject (data) {
      this.$refs.projectDetailEditor.editProject(data);
      // this.projectId = data;
      // this.getProjectDetail(data);
    },
    // getProjectDetail (id) {
    //   const _this = this;
    //   projectDetail(id).then((response) => {
    //     if (response.code == 200) {
    //       _this.newProjectOpen = true;
    //       _this.$nextTick(() => {
    //         _this.project.name = response.data.name;
    //         _this.project.cover = response.data.cover;
    //         _this.project.details = response.data.details;
    //         _this.project.tagList = response.data.tagList;
    //         _this.project.fileList = response.data.fileList;
    //         _this.project.enableDesign = response.data.enableDesign;

    //         // 给aliOSS设置agencyId
    //         _this.formFilesUpload.config = {
    //           formId: response.data.projectId,
    //           agencyId: response.data.agencyId,
    //         };
    //       });

    //       _this.$forceUpdate();
    //     }
    //   });
    // },
    // 创建表单
    onNewFormClick () {
      this.$router.push({
        path: "/form/new",
      });
    },
    templateCreateFormCommand (command) {
      if (command === "1") {
        this.templateImportVisible = true;
      }
    },
    createForm () {
      this.title = "从模版创建表单";
      this.templateImportType = "form";
      this.templateImportVisible = true;
    },
    // 生成随机的6位数字
    generateRandomNumber () {
      return Math.floor(100000 + Math.random() * 900000);
    },
    createFormByAI () {
      // WebSocket对象
      let webSocket;
      // WebSocket初始化
      let self = this;

      function scrollToEnd () {
        let textAreaRef;
        if (!textAreaRef) {
          var childElements = this.$refs.aiTextarea.$el.childNodes;

          // 遍历子级元素集合
          for (var i = 0; i < childElements.length; i++) {
            var childElement = childElements[i];
            if (childElement.className === 'el-textarea__inner') {
              textAreaRef = childElement;
            }
          }
        }
        textAreaRef.scrollTop = textAreaRef.scrollHeight;
      }
      function initWebSocket () {
        //判断当前浏览器是否支持WebSocket, 主要此处要更换为自己的地址,ws等同于http，wss等同于https
        if ('WebSocket' in window) {
          //webSocket = new WebSocket('ws://localhost:9001/websocket/' + self.user.userId +"-"+ self.random.toString());
          webSocket = new WebSocket(websocketUrl + self.user.userId + "-" + self.random.toString());
          console.log("WebSocket连接成功");
          // 打开事件
          webSocket.onopen = function () {
            console.log("WebSocket打开");
          };
          // 关闭事件
          webSocket.onclose = function () {
            console.log("WebSocket关闭");
            // 初始化WebSocket
            //initWebSocket();
          };
          // 消息事件
          webSocket.onmessage = function (message) {
            console.log("WebSocket接收消息：" + message.data);
            self.formData.answer += message.data;
            self.$nextTick(scrollToEnd)
          }.bind(this);
          // 错误事件
          webSocket.onerror = function () {
            console.log("WebSocket发生错误");
          };
        } else {
          console.log("WebSocket连接失败");
        }
      };
      // 初始化WebSocket
      initWebSocket();
      // 使用定时器发送心跳包
      this.intervalHandlerQwen = setInterval(() => {
        if (webSocket.readyState == 1) {
          webSocket.send("心跳包检测");
        } else {
          console.log("WebSocket链接断开");
          // 初始化WebSocket
          initWebSocket();
        }
      }, 5 * 1000);

        this.title = "";
        this.templateImportType = "form";
        this.createFormAIVisible = true;
      },
    closeAiDialog(){
        clearInterval(this.intervalHandlerQwen);
      },
    createProject() {
        this.title = "从模版创建应用";
        this.templateImportType = "project";
        this.templateImportVisible = true;
      },
    // 处理文件上传操作
    handleFileUpload(file) {
        const loading = this.$loading({
          lock: true,
          text: '正在进行导入，请稍等...',
          spinner: 'el-icon-loading',
        });

      if (this.templateImportType === "form") {
        // 调用后端服务器的接口
        createFormByTemplate(file.file, this.agencyId)
          .then((res) => {
            if (res.code == 200) {
              loading.close();
              this.templateImportVisible = false;
              this.getProjectList(this.cur);
            } else {
              loading.close();
              this.$message.error(res.data);
            }
          })
          .catch((e) => {
            this.$message.error(e.message);

            this.$refs.fileUpload&& this.$refs.fileUpload.clearFiles();
            loading.close();
          });
      } else {
        // 调用后端服务器的接口
        createProjectByTemplate(file.file, this.agencyId)
          .then((res) => {
            if (res.code == 200) {
              loading.close();
              this.templateImportVisible = false;
              this.getProjectList(this.cur);
            } else {
              loading.close();
              this.$message.error(res.data);
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
            this.$refs.fileUpload&& this.$refs.fileUpload.clearFiles();
            loading.close();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('~@/assets/styles/filetype.css');
[v-cloak] {
  display: none;
}
.button-container {
  text-align: center;
  margin-top: 10px;
}

.my-form-left {
  background: #ffffff;
  border-radius: 4px;
  .tab-title {
    margin: 0;
    padding: 16px;
    li {
      width: 100%;
      text-align: left;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      overflow: hidden;
      margin: 0 0 8px;
      list-style: none;
      font-size: 14px;
      color: #333333;
      background: rgba(165, 171, 177, 0.05);
      border-radius: 4px;
      font-weight: 500 !important;
      &.active {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        position: relative;
        border-radius: 6px;
        font-weight: 800 !important;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 30px;
          left: 0;
          top: 50%;
          margin-top: -15px;
          background: #3476f0;
          opacity: 0.4;
          border-radius: 0 3px 3px 0;
        }
      }
      &:hover {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        border-radius: 6px;
        font-weight: 500 !important;
      }

      .tab-title-icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        margin-right: 8px;
        vertical-align: -5px;
      }
    }
  }
  &.isFixed {
    position: fixed;
    top: 0;
    -webkit-transform: translateZ(0);
  }
}
.my-form-right {
  width: 1184px;
  min-height: 220px;
  padding: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #ffffff;
  // border-left: 1px solid #e5e5e5;
  flex: 1;
  -webkit-flex: 1;
  .form-list-top {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    .form-top-btn {
      font-size: 14px;
      height: 28px;
      &:hover {
        opacity: 0.8;
      }
    }
    .btn-create-icon {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  .tab-content {
    width: 100%;
    height: 100%;
  }
  .search-input{
    ::v-deep .el-input__inner{
      padding-right:10px;
    }
  }
}
.anim-fade-in {
  animation: 1s slideLeftActive;
}
@keyframes slideLeftActive {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
.uploader-hover {
  position: absolute;
  width: 200px;
  height: 120px;
  line-height: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:hover {
    background: rgba(0, 0, 0, 0.35);
    &:after {
      content: '修改封面图片';
      font-size: 14px;
    }
  }
  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 128px;
    line-height: 128px;
    text-align: center;
  }
}
.cover {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 8px;
}
.line {
  padding: 0;
  text-align: left;
  margin-left: 30px;
  span {
    display: block;
    line-height: 20px;
    font-size: 13px;
    color: #999999;
  }
}
.form-type-content {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  .type-content-default {
    width: 50%;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 156px;
      height: 120px;
      margin-top: 40px;
    }
  }
  .type-content-page {
    width: 50%;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 135px;
      height: 126px;
      margin-top: 40px;
    }
  }
}

.list-switch {
  background-color: #f7f7f7;
  padding: 2px;
  border-radius: 4px;
  margin-top:4px;
  ::v-deep .el-radio-button__inner {
    background-color: #f7f7f7;
    color: #999999;
    padding: 5px 12px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #333333;
    background-color: #fff;
    border-color: #dcdfe6;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  }
}

.uploadCover {
  display: flex;
  padding: 8px 0px;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
    border-radius: 4px;
    cursor: pointer;
  }

  .uploadCover-icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background-color: #f7f7f7;
    border-radius: 4px;
  }
}

::v-deep .el-upload-dragger,
::v-deep .el-upload{
  width:100%;
}
</style>
